import React from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import SEO from "../components/seo";
import Layout from "../components/full-page/layout";
import { ColorThemeContextProvider } from "../contexts/colorThemeContext";
import CCLogoGray from '../images/cccluegrey.svg';
import RichText from "../components/common/rich-text";

const ContentPage = ({ data, pageContext: { lang } }) => {
  if (!data) {
    return null;
  }

  const document = data.prismicContentPage;

  const meta = [
    {
      name: `description`,
      content: document.data.meta_description.text,
    },
    {
      property: `og:title`,
      content: document.data.meta_title.text,
    },
    {
      property: `og:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `twitter:description`,
      content: document.data.meta_description.text,
    },
    {
      name: `og:image`,
      content: document.data.share_image.url,
    },
  ];

  return (
    <>
      <SEO title={document.data.title.text} meta={meta} canonical_link={document.data.canonical_link?.url} />
      <ColorThemeContextProvider>
        <Layout
          disappearHeader={true}
          initColorTheme={'light'}
          logo={CCLogoGray}
          topNav={data.prismicHome?.data.menu_links}
          footerNav={data.prismicHome?.data.footer_links}
          blogListing={data.allPrismicBlogPost?.nodes}
          currentLanguage={lang}
        >
          <div className="page-container text-page-wrapper">
            <RichText>{document.data.content.html}</RichText>
          </div>

        </Layout>
      </ColorThemeContextProvider>
    </>
  )
};

export const query = graphql`
query ContentPageQuery($id: String, $lang: String) {
  prismicContentPage(id: {eq: $id}, lang: {eq: $lang}) {
    _previewable
    id
    lang
    type
    url
    data {
      title {
        text
      }
      meta_title {
        text
      }
      share_image {
        url
      }
      meta_description {
        text
      }
      canonical_link {
        url
      }
      content {
        html
      }
    }
  }
  
  prismicHome(lang: {eq: $lang}) {
    data {
      menu_links {
          menu_link {
              url
              type
          }
          name {
            text
          }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`

export default withPrismicPreview(ContentPage);